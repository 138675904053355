import React from "react";
import "./Rodape.css";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaInstagram,
  FaWhatsapp,
  FaLock,
  FaTruck,
} from "react-icons/fa";
import { SiPix } from "react-icons/si";
import { MdLocalShipping } from "react-icons/md";
import logo from "./IMG/logorodape.png";
import correios from "./IMG/correios.png";
import ssl from "./IMG/ssl.png";

const Rodape = () => {
  return (
    <footer className="rodape">
      <div className="rodape-info">
        <img src={logo} alt="Logo" className="rodape-logo" />
        <p>Moda e Vestuário</p>
        <p>© 2024 CodeX - Especialista em Lojas Virtuais</p>
      </div>

      <div className="rodape-departamentos">
        <h3>Sobre a Casa da Moda</h3>
        <ul>
          <li>
            <a  href="https://wa.me/5538991360110">Nossa História</a>
          </li>
          <li>
            <a  href="https://wa.me/5538991360110">Missão, Visão e Valores</a>
          </li>
        </ul>
      </div>

      <div className="rodape-social">
        <h3>UA Social</h3>
        <div className="social-icons">
          <a
            href="https://wa.me/5538991360110"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp className="social-icon" />
            <span>Whatsapp</span>
          </a>
          <a
             href="https://www.instagram.com/casadamodamoc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="social-icon" />
            <span>Instagram</span>
          </a>
        </div>
      </div>

      <div className="rodape-envio-social-seguranca">
        <div className="rodape-envio">
          <h3>Meios de envio</h3>
          <div className="bandeiras-envio">
            <MdLocalShipping />
            <img src={correios} alt="Correios" className="correios-logo" />
          </div>
        </div>

        <div className="rodape-contato">
          <h3>Entre em contato</h3>
          <p>Telefone: (38) 99136-0110</p>
        <p>Email: casadamodamoc@gmail.com</p>
        </div>

        <div className="rodape-pagamento-envio">
          <h3>Meios de pagamento</h3>
          <div className="bandeiras-pagamento">
            <FaCcVisa />
            <FaCcMastercard />
            <FaCcAmex />
            <FaCcDiscover />
            <SiPix />
          </div>
        </div>

        <div className="rodape-seguranca">
          <h3>Segurança</h3>
          <div className="seguranca-icones">
            <FaLock />
            <img src={ssl} alt="SSL" className="ssl-icon" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Rodape;
