import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
} from "react-router-dom";
import {
  FaInstagram,
  FaWhatsapp,
  FaShoppingCart,
  FaShippingFast,
  FaHeadset,
  FaLock,
} from "react-icons/fa";
import "./App.css";
import logo from "./IMG/logo.png";
import banner from "./IMG/banner.png";
import banner2 from "./IMG/banner2.png";
import { db } from "./firebase";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import Carrinho from "./Carrinho";
import Produto from "./Produto";
import Rodape from "./Rodape";
import Checkout from "./Checkout";
import Lupa from "./Lupa";
import Categorias from "./Categorias";
import TextoRotativo from './TextoRotativo'; 
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      const q = query(collection(db, "products"), orderBy("createdAt", "desc")); // Ordena pela data de criação
      const querySnapshot = await getDocs(q);
      const productsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setProducts(productsData);

      // Extrair categorias únicas dos produtos
      const uniqueCategories = [
        ...new Set(productsData.map((product) => product.category)),
      ];
      setCategories(uniqueCategories);
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duração da animação
      once: false, // Permite animações repetidas ao descer e subir
      throttleDelay: 50, // Menor atraso entre eventos de scroll
    });
  }, []);

  const handleAddToCart = (product) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) =>
          item.name === product.name &&
          item.selectedSize === product.selectedSize
      );

      if (existingItem) {
        return prevItems.map((item) =>
          item.name === product.name &&
          item.selectedSize === product.selectedSize
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });
    setIsCartOpen(true); // Abre o carrinho após adicionar o item
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <Router>
      <div className="app">
      <TextoRotativo />  
        <header className="header">
          <Link to="/inicio">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
          <nav className="nav">
            <ul className="menu">
              <li>
                <Link to="/inicio">Início</Link>
              </li>
              {categories.map((category) => (
                <li key={category}>
                  <Link to={`/${category.toLowerCase()}`}>{category}</Link>
                </li>
              ))}
            </ul>

            <div className="icons">
              <a
                href="https://wa.me/5538991360110"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp />
              </a>
              <a
                href="https://www.instagram.com/casadamodamoc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
              <Lupa products={products} />
              <button className="cart-icon" onClick={toggleCart}>
                <FaShoppingCart />
              </button>
              <Categorias categories={categories} />
            </div>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Navigate to="/inicio" />} />
          <Route path="/inicio" element={<Home products={products} />} />
          {categories.map((category) => (
            <Route
              key={category}
              path={`/${category.toLowerCase()}`}
              element={
                <Category
                  products={products.filter(
                    (product) => product.category === category
                  )}
                />
              }
            />
          ))}
          {products.map((product) => (
            <Route
              key={product.id}
              path={`/${normalizeString(product.name)}`}
              element={
                <Produto product={product} onAddToCart={handleAddToCart} />
              }
            />
          ))}
          <Route
            path="/checkout"
            element={<Checkout cartItems={cartItems} />}
          />
          {/* Rota catch-all para redirecionar URLs não definidas */}
          <Route path="*" element={<Navigate to="/inicio" />} />
        </Routes>
        {isCartOpen && <Carrinho items={cartItems} onClose={toggleCart} />}
        <Rodape />
      </div>
    </Router>
  );
}

function normalizeString(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-zA-Z0-9]/g, "-")
    .toLowerCase();
}

function Home({ products }) {
  return (
    <div className="inicio">
      <main>
        {/* Primeiro banner com efeito de hover e AOS */}
        <img
          src={banner}
          alt="Banner"
          className="banner"
          data-aos="fade-down" // Animação ao descer
          data-aos-anchor-placement="top-center" // Define o ponto de início da animação
        />

        <h2 className="products-title">Destaques:</h2>
        <div className="product-list">
          {products.map((product, index) => (
            <div
              key={index}
              className="product-item"
              data-aos="fade-up" // Efeito de fade-up nos produtos
              data-aos-delay={index * 100} // Adiciona um pequeno atraso para cada produto
            >
              <Link to={`/${normalizeString(product.name)}`}>
                <img
                  src={product.imageUrls[0]}
                  alt={product.name}
                  className="product-image"
                />
              </Link>
              <h3>{product.name}</h3>
              <p className="price">{formatPrice(product.price)}</p>
            </div>
          ))}
        </div>

        {/* Segundo banner com efeito de hover e AOS */}
        <img
          src={banner2}
          alt="Banner 2"
          className="banner2"
          data-aos="fade-up" // Animação ao descer
          data-aos-anchor-placement="top-center" // Define o ponto de início da animação
        />

        <div className="mobile-banner">
          <video
            className="banner-video"
            autoPlay
            muted
            loop
            playsInline
            onLoadedData={(e) => e.target.play()}
          >
            <source src={require("./IMG/banner4.mp4")} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Seções de informações com efeito de hover e AOS */}
        <div className="info-sections">
          <div
            className="info-item"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <FaShippingFast className="info-icon" />
            <h3>Pedido Rastreável</h3>
            <p>Todos os pedidos com rastreios 24H.</p>
          </div>
          <div
            className="info-item"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <FaHeadset className="info-icon" />
            <h3>Atendimento</h3>
            <p>Suporte de segunda a sexta 09h - 18h.</p>
          </div>
          <div
            className="info-item"
            data-aos="fade-right"
            data-aos-duration="1400"
          >
            <FaLock className="info-icon" />
            <h3>Pagamento seguro</h3>
            <p>Compras 100% seguras.</p>
          </div>
        </div>
      </main>
    </div>
  );
}

function Category({ products }) {
  return (
    <div className="category">
      <main>
        <h2 className="products-title">Produtos:</h2>
        <div className="product-list">
          {products.map((product, index) => (
            <div key={index} className="product-item">
              <Link to={`/${normalizeString(product.name)}`}>
                <img
                  src={product.imageUrls[0]}
                  alt={product.name}
                  className="product-image"
                />
              </Link>
              <h3>{product.name}</h3>
              <p className="price">{formatPrice(product.price)}</p>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}

const formatPrice = (price) => {
  if (typeof price === "number") {
    return `R$ ${price
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
  }
  return price;
};

export default App;
